var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.question.FormQuestionOptions),function(option,j){return _c('v-list-item',{key:j,staticStyle:{"pointer-events":"none"}},[_c('v-list-item-title',[_vm._v(_vm._s(j + 1 + '. ' + option.Text))]),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1),_c('v-list-item-action',[(
          _vm.formUser &&
          _vm.formUser.FormUserQuestions &&
          !_vm.formUser.FormUserQuestions.map((s) =>
            s.FormUserQuestionOptions.find((d) => {
              return d.QuestionOptionId == option.Id
            })
          ).filter((s) => s).length
        )?_c('v-btn',{attrs:{"icon":"","loading":_vm.loadingSaveAnswer}},[_c('v-icon',{staticStyle:{"pointer-events":"auto"},attrs:{"color":"grey darken-1"},on:{"click":function($event){return _vm.saveFormUserQuestionOption(_vm.question, option)}}},[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1):_vm._e(),(
          _vm.formUser &&
          _vm.formUser.FormUserQuestions &&
          _vm.formUser.FormUserQuestions.map((s) =>
            s.FormUserQuestionOptions.find((d) => {
              return d.QuestionOptionId == option.Id
            })
          ).filter((s) => s).length
        )?_c('v-btn',{attrs:{"icon":"","loading":_vm.loadingSaveAnswer}},[_c('v-icon',{staticStyle:{"pointer-events":"auto"},attrs:{"color":"grey darken-2"},on:{"click":function($event){return _vm.deleteFormUserQuestionOption(_vm.question, option)}}},[_vm._v(" mdi-checkbox-marked-circle ")])],1):_vm._e()],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }